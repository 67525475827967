<template>
  <en-drawer title="配件管理费" :model-value="modelValue" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :loading="form.loading" :ref="setRef('form')" label-position="left" label-width="120">
      <en-form-item label="配件管理费率" prop="managementFeeRate">
        <select-maintain
          v-model="form.data.managementFeeRate"
          :ajax="{
            action: 'GET /enocloud/common/hint/:hintTypeStr',
            params: (params) => (params.paths = ['SVOTCRT'])
          }"
          :props="{ label: (option: EnocloudCommonDefinitions['HintDto']) => formatPercent(Number(option.name)), value: 'name' }"
          class="w-full"
          @change="form.managementFeeRate.change"
        >
          <template #default="{ option }">{{ formatPercent(Number(option.name)) }}</template>
        </select-maintain>
      </en-form-item>
      <en-form-item label="产生金额">
        {{ formatMoney(form.data.managementFee) }}
      </en-form-item>
      <en-form-item label="产生事由">
        <en-input v-model="form.data.managementFeeReason"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { calculator } from '@enocloud/utils'

export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudServiceDefinitions['ServiceDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  watch: {
    modelValue: {
      handler(visible) {
        if (visible) {
          this.form.get()
        } else {
          this.form.init()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/:serviceId',
            data: 'object',
            init: true,
            loading: true,
            params(params) {
              params.paths = [this.data?.id]
            }
          },
          submit: {
            action: 'PUT /enocloud/service',
            validate: true,
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        rules: {
          managementFeeRate: { required: true, type: 'number', message: '请选择配件管理费率' }
        },
        children: {
          managementFeeRate: {
            change(value: string) {
              this.form.data.managementFeeRate = parseFloat(value)
              const { goods = [] } = this.form.data
              this.form.data.managementFee = goods
                .filter((item) => item.chargingMethod?.includedFee)
                .reduce((sum, item) => {
                  return calculator.add(sum, calculator.mul(item.amountBeforeDiscount, this.form.data.managementFeeRate))
                }, 0)
            }
          }
        }
      },
      footer: {
        children: {
          cancel: {
            click() {
              this.emit('update:model-value', false)
            }
          },
          confirm: {
            async click() {
              await this.form.submit()
              this.emit('update:model-value', false)
              this.emit('confirm')
            }
          }
        }
      }
    }
  }
})
</script>
